import axios from "axios";

const clienteAxios = axios.create({
  baseURL: process.env.REACT_APP_ZEUS_API,
});

export const clienteZonaBeneficiarios = axios.create({
  baseURL: process.env.REACT_APP_ZONA_BENEFICIARIOS_API,
});

export const clienteGeneralAxios = axios.create({
  baseURL: process.env.REACT_APP_GENERAL_API,
});

export const clientPortalAaxios = axios.create({
  baseURL: process.env.REACT_APP_PORTAL_API,
});
export default clienteAxios;
