import React, { useContext, useEffect, useRef, useState } from "react";
import { LoginForm } from "../index";
import Slider from "react-slick";

import AuthContext from "../../../../context/autenticacion/authContext";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import zIndex from "@mui/material/styles/zIndex";
import imgX from "../../../../assets/img/Sello-BCX.png";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const Section1 = (props) => {
  const { onSubmit, loading, error } = props;
  const authContext = useContext(AuthContext);
  const { dataBlock, limpiarVariablesAccountBlock } = authContext;
  const [isMobile, setIsMobile] = useState(false);
  const [windowWidth, setWindowWidth] = useState(getWidth());
  const [isPlayed, setIsPlayed] = useState(true);
  let sliderRef = useRef(null);
  const myRef = useRef(null);

  const pause = () => {
    if (isPlayed) {
      setIsPlayed(false);
      sliderRef.current.slickPause();
    } else {
      setIsPlayed(true);
      sliderRef.current.slickPlay();
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
  };

  const assignTabIndex = () => {
    if (
      sliderRef.current &&
      sliderRef.current.innerSlider &&
      sliderRef.current.innerSlider.list
    ) {
      const dotButtons =
        sliderRef.current.innerSlider.list.parentNode.querySelectorAll(
          ".slick-dots button"
        );
      dotButtons.forEach((button, index) => {
        button.tabIndex = -1;
      });
    }
  };

  useEffect(() => {
    assignTabIndex();
  }, []);

  useEffect(() => {
    const resizeListener = () => {
      setWindowWidth(getWidth());
    };

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [window.screen.width]);

  useEffect(() => {
    if (windowWidth > 980 && isMobile === true) {
      setIsMobile(false);
    } else if (windowWidth <= 980 && isMobile === false) setIsMobile(true);
  }, [windowWidth]);

  const renderReactComponent = (rootElement) => {
    import("react-dom").then((ReactDOM) => {
      ReactDOM.render(
        <div onClick={pause} style={{ cursor: "pointer" }} id="slidesPlay">
          {isPlayed ? (
            <PauseIcon fontSize="medium" sx={{ color: "#a40023" }} />
          ) : (
            <PlayArrowIcon fontSize="medium" sx={{ color: "#a40023" }} />
          )}
        </div>,
        rootElement
      );
    });
  };

  useEffect(() => {
    if (windowWidth > 959) {
      const listItem = document.createElement("li");
      renderReactComponent(listItem);
      const list = document.querySelector("ul.slick-dots");

      if (document.getElementById("slidesPlay") && list && list.lastChild) {
        const lastChild = list.lastChild;
        const slidesPlayDiv = lastChild.querySelector("div#slidesPlay");
        if (slidesPlayDiv) list.removeChild(list.lastChild);
      }
      list.appendChild(listItem);
    } else {
      const list = document.querySelector("ul.slick-dots");
      if (document.getElementById("slidesPlay") && list && list.lastChild) {
        const lastChild = list.lastChild;
        const slidesPlayDiv = lastChild.querySelector("div#slidesPlay");
        if (slidesPlayDiv) list.removeChild(list.lastChild);
      }
    }
  }, [isPlayed, windowWidth]);

  return (
    <section className="section1" ref={myRef}>
      <div className="banner-container">
        <Slider ref={sliderRef} {...settings}>
          {/* <div className="banner-section bg-baner-11">
            <div className="container-new-home banner-content">
              <img src={imgX} alt="" className="img-banner-11-izo" />
              <div className="banner-info">
                <p className="subtitle-1-banner-11">
                  Si en el mes del amor,<br></br>con esto no te enamoras:
                </p>
                <p style={{ marginBottom: 0 }}>
                  <span className="subtitle-2-banner-11">N°1</span>
                  <span className="subtitle-3-banner-11">en rentabilidad</span>
                </p>
                <p style={{ marginTop: 0 }}>
                  <span className="subtitle-2-banner-11">N°1</span>
                  <span className="subtitle-3-banner-11">en servicio</span>
                </p>
                <p>
                  <span className="subtitle-4-banner-11">
                    Mejor que te lo diga
                  </span>
                  <span className="subtitle-5-banner-11">
                    Luciano Mazzeti o Janick Maceta
                  </span>
                </p>
                <p
                  style={{
                    display: "flex",
                    color: "white",
                    alignItems: "center",
                  }}
                >
                  <div className="icon-phone">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27px"
                      height="1em"
                      viewBox="0 0 32 32"
                    >
                      <path
                        fill="currentColor"
                        d="m20.33 21.48l2.24-2.24a2.19 2.19 0 0 1 2.34-.48l2.73 1.09a2.18 2.18 0 0 1 1.36 2v5A2.17 2.17 0 0 1 26.72 29C7.59 27.81 3.73 11.61 3 5.41A2.17 2.17 0 0 1 5.17 3H10a2.16 2.16 0 0 1 2 1.36l1.09 2.73a2.16 2.16 0 0 1-.47 2.34l-2.24 2.24s1.29 8.73 9.95 9.81"
                      />
                    </svg>
                  </div>
                  <span className="subtitle-6-banner-11">01 7309221</span>
                </p>
              </div>
              <button
                className="btn-primary-new-home panel-traspaso"
                style={{ zIndex: 100, marginTop: "3.4%" }}
                onClick={() =>
                  window.open(
                    "https://portal.afphabitat.com.pe/privado/afiliate-a-habitat?utm_source=home&utm_medium=banner&utm_campaign=rentabilidad_servicio",
                    "__blank"
                  )
                }
              > 
                <span>Cámbiate a Habitat</span>
              </button>
            </div>
          </div> */}
          {/* <div className="banner-section bg-baner-15">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <div style={{ marginBottom: "18px" }} className="box-baner-15">
                  <p className="txt-title1-banner-15">
                    Premiados por el Perú Golden Awards como:
                  </p>
                  <p className="txt-title2-banner-15">
                    Mejor Gestor de Inversiones
                  </p>
                  <p className="txt-title2-banner-15">
                    en todos los fondos 2023
                  </p>
                </div>
                <p className="txt-banner-15">
                  Por nuestra rentabilidad de los fondos 0, 1, 2 y 3,
                  <br /> en la categoría AFP - 2023.
                </p>
              </div>
              <button
                className="btn-primary-new-home panel-traspaso btn-info"
                style={{ zIndex: 100, borderRadius: "100px" }}
                tabIndex={"-1"}
                onClick={() =>
                  window.open(
                    "https://cambiate.afphabitat.com.pe/identidad?utm_source=home&utm_medium=banner&utm_campaign=traspasos",
                    "__blank"
                  )
                }
              >
                Cámbiate a Habitat
              </button>
              <div className="txt-subtitle-banner-15">
                La rentabilidad de los distintos tipos de fondos de pensiones es
                variable, su <br className="mobile-15" />
                nivel en el futuro puede cambiar <br className="desktop-15" />{" "}
                en relación con la rentabilidad pasada.
              </div>
            </div>
          </div> */}
          <div className="banner-section bg-baner-18">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <div class="overlay"></div>
              </div>
              <button
                className="btn-primary-new-home panel-traspaso btn-info"
                style={{ zIndex: 100, borderRadius: "100px" }}
                tabIndex={"-1"}
                onClick={() =>
                  window.open(
                    "https://cambiate.afphabitat.com.pe/identidad?utm_source=home&utm_medium=banner_soprano&utm_campaign=traspasos",
                    "__blank"
                  )
                }
              >
                Cámbiate ahora
              </button>
              <div className="txt-subtitle-banner-18">
                *Fuente: Boletín Estadístico Mensual SBS agosto 2024.
                Rentabilidad Nominal Acumulada del Fondo de Pensiones Tipo 1, 2
                y 3 por AFP. Agosto 2024 - agosto 2013. www.sbs.gob.pe.{" "}
                <span>
                  La rentabilidad de los distintos tipos de fondos de pensiones
                  es variable, su nivel en el futuro puede cambiar en relación
                  con la rentabilidad pasada.
                </span>
              </div>
            </div>
          </div>
          <div className="banner-section bg-baner-17">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <div style={{ marginBottom: "18px" }} className="box-baner-17">
                  <p className="txt-banner-17">Conoce todo sobre la</p>
                  <p className="txt-title1-banner-17">Ley de modernización del</p>
                  <p className="txt-title2-banner-17">Sistema Previsional Peruano</p>
                </div>
              </div>
              <button
                className="btn-primary-new-home panel-traspaso btn-info"
                style={{ zIndex: 100 }}
                tabIndex={"-1"}
                onClick={() =>
                  window.open(
                    "https://www.afphabitat.com.pe/nueva-reforma-del-sistema-de-pensiones/",
                    "__blank"
                  )
                }
              >
                Más información
              </button>
            </div>
          </div>
          {/* <div className="banner-section bg-baner-16">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <div style={{ marginBottom: "18px" }} className="box-baner-16">
                  <p className="txt-title1-banner-16">
                    Nuestro{" "}
                    <span className="txt-title2-banner-16 margin-title">
                      consistente
                    </span>
                  </p>
                  <p className="txt-title2-banner-16">
                    liderazgo en rentabilidad
                  </p>
                </div>
                <p className="txt-banner-16">
                  Nos mantiene en la cima en los tipos de fondos
                  <br /> 1, 2 y 3 a largo plazo (a 11 años).
                </p>
              </div>
              <button
                className="btn-primary-new-home panel-traspaso btn-info"
                style={{ zIndex: 100, borderRadius: "100px" }}
                tabIndex={"-1"}
                onClick={() =>
                  window.open(
                    "https://cambiate.afphabitat.com.pe/identidad?utm_source=home&utm_medium=banner_montana&utm_campaign=traspasos",
                    "__blank"
                  )
                }
              >
                Cámbiate ahora
              </button>
              <div className="txt-subtitle-banner-16">
                *Fuente: Boletín Estadístico Mensual SBS agosto 2024.
                Rentabilidad Nominal Acumulada del Fondo de Pensiones Tipo 1, 2
                y 3 por AFP. Agosto 2024 - agosto 2013. www.sbs.gob.pe.{" "}
                <span>
                  La rentabilidad de los distintos tipos de fondos de pensiones
                  es variable, su nivel en el futuro puede cambiar en relación
                  con la rentabilidad pasada.
                </span>
              </div>
            </div>
          </div> */}
          <div className="banner-section bg-baner-12">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <div style={{ marginBottom: "18px" }} className="box-baner-12">
                  <p className="txt-title1-banner-12">Nuevo retiro del</p>
                  <p className="txt-title2-banner-12">fondo de jubilación</p>
                </div>
                <p className="txt-banner-12">Todo lo que necesitas saber</p>
              </div>
              <button
                className="btn-primary-new-home panel-traspaso btn-info"
                style={{ zIndex: 100 }}
                tabIndex={"-1"}
                onClick={() =>
                  window.open(
                    "https://www.afphabitat.com.pe/retiro-de-afp/",
                    "__blank"
                  )
                }
              >
                Más información
              </button>
            </div>
          </div>
          <div className="banner-section bg-baner-13">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <div style={{ marginBottom: "18px" }} className="box-baner-13">
                  <p className="txt-title1-banner-13">
                    Recuerda que es importante
                  </p>
                  <p className="txt-title2-5-banner-13">
                    mantener tus datos actualizados
                  </p>
                  <p className="txt-title2-banner-13">mantener tus datos</p>
                  <p className="txt-title2-banner-13">actualizados</p>
                </div>
                <p className="txt-banner-13">Hazlo en Mi Habitat Digital</p>
              </div>
              <button
                className="btn-primary-new-home panel-traspaso btn-info"
                style={{ zIndex: 100 }}
                tabIndex={"-1"}
                onClick={() =>
                  window.open(
                    "https://www.afphabitat.com.pe/#/mi-habitat-digital/empezar",
                    "__blank"
                  )
                }
              >
                Ir a Mi Habitat Digital
              </button>
            </div>
          </div>
          {/* <div className="banner-section bg-baner-14">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <div style={{ marginBottom: "18px" }} className="box-baner-14">
                  <p className="txt-title1-banner-14">Líderes indiscutibles</p>
                  <p className="txt-title2-banner-14">en todos los fondos</p>
                </div>
                <p className="txt-banner-14">
                  Cerramos como líderes en rentabilidad en todos los
                  <br />
                  fondos generando ganancias para nuestros afiliados.
                </p>
              </div>
              <button
                className="btn-primary-new-home panel-traspaso btn-info"
                tabIndex={"-1"}
                style={{ zIndex: 100 }}
                onClick={() =>
                  window.open(
                    "https://www.afphabitat.com.pe/rentabilidad/",
                    "__blank"
                  )
                }
              >
                Ver resultados
              </button>
            </div>
          </div> */}
          {/* <div className="banner-section bg-baner-10">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <h1>LÍDERES</h1>
                <h1>INDISCUTIBLES</h1>
                <h1>EN TODOS LOS</h1>
                <h1>FONDOS</h1>
                <p className="txt-banner-10">
                  Cerramos como líderes en rentabilidad en todos los fondos{" "}
                  <br></br>generando ganancias para nuestros afiliados
                </p>
              </div>
              <button
                className="btn-primary-new-home panel-traspaso"
                style={{ zIndex: 100 }}
                onClick={() =>
                  window.open(
                    "https://www.afphabitat.com.pe/rentabilidad/",
                    "__blank"
                  )
                }
              >
                <span>Ver resultados</span>
              </button>
            </div>
          </div> */}
          {/* <div className="banner-section bg-baner-9">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <h1>Líderes en</h1>
                <h1>Servicio por 5 años</h1>
                <h1>consecutivos</h1>
                <p className="txt-banner-9">
                  Haz tus operaciones fáciles <br></br>y rápidas por nuestros
                  canales<br></br> de atención
                </p>
              </div>
            </div>
          </div> */}
          {/* <div className="banner-section bg-baner-8">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <h1>Mejor gestor de inversiones en los Fondos 1, 2 y 3</h1>
                <p className="txt-banner-8">
                  Reconocidos por el Perú Golden Awards, gestión 2022.
                </p>
              </div>
              <button
                className="btn-primary-new-home"
                onClick={() => props.setHideSection(true)}
              >
                ¡Comienza ahora!
              </button>
            </div>
          </div> */}
          {/* <div className="banner-section bg-baner-7">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <h1 className="banner-info__title">Ley de</h1>
                <h1 className="banner-info__title">pensión mínima</h1>
                <p className="banner-info__description">
                  ¡Todo lo que necesitas
                </p>
                <p className="banner-info__description">saber, en fácil!</p>
              </div>
              <button
                className="btn-primary-new-home panel-traspaso"
                style={{ zIndex: 100 }}
                onClick={() =>
                  window.open(
                    "https://www.afphabitat.com.pe/pension-minima-objetivo/",
                    "__blank"
                  )
                }
              >
                <span>Más información</span>
              </button>
            </div>
          </div> */}
          {/* <div className="banner-section bg-baner-6">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <h1 className="banner-info__title">
                  Sé más rentable desde hoy
                </h1>
                <p className="banner-info__description">
                  Cámbiate a AFP Habitat en solo 3 pasos. ¡Es fácil, rápido y
                  100% digital!
                </p>
              </div>
              <button
                className="btn-primary-new-home panel-traspaso"
                style={{ zIndex: 100 }}
                onClick={() =>
                  window.open(
                    "https://portal.afphabitat.com.pe/privado/afiliate-a-habitat?utm_source=home&utm_medium=banner&utm_campaign=traspasos",
                    "__blank"
                  )
                }
              >
                <span>Cámbiate a Habitat</span>
              </button>
            </div>
          </div>
          <div className="banner-section bg-baner-5">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <h5>INVIERTE SIN MONTO MÍNIMO Y RETIRA CUANDO QUIERAS</h5>
                <h1>Persigue tus metas con Fondo Libre Habitat</h1>
                <p>¡Es fácil, rápido y 100% digital!</p>
              </div>
              <button
                style={{ zIndex: 100 }}
                className="btn-primary-new-home panel-persigue"
                onClick={() =>
                  window.open(
                    "https://www.afphabitat.com.pe/fondo-libre-habitat/?utm_source=home&utm_medium=banner&utm_campaign=fondo-libre",
                    "__blank"
                  )
                }
              >
                <span>Quiero saber más</span>
              </button>
            </div>
          </div>
          <div className="banner-section  bg-baner-1">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <h1>Mi Habitat Digital</h1>
                <span>DISPONIBLE LAS 24x7</span>
                <p>¡Ingresa ahora!</p>
              </div>
              <button
                className="btn-primary-new-home"
                onClick={() => props.setHideSection(true)}
              >
                ¡Comienza ahora!
              </button>
            </div>
          </div>
          <div className="banner-section bg-baner-3">
            <div className="container-new-home banner-content">
              <div className="banner-info">
                <h1>Solicita tu jubilación en línea</h1>

                <p>¡Es fácil y seguro!</p>
              </div>
              <button
                className="btn-primary-new-home"
                onClick={() => props.setHideSection(true)}
              >
                ¡Comienza ahora!
              </button>
            </div>
          </div> */}
        </Slider>
        {isMobile && (
          <div
            onClick={pause}
            style={{ cursor: "pointer" }}
            className="slideMobile"
          >
            {isPlayed ? (
              <PauseIcon fontSize="medium" sx={{ color: "#ffffff" }} />
            ) : (
              <PlayArrowIcon fontSize="medium" sx={{ color: "#ffffff" }} />
            )}
          </div>
        )}

        <div className="desktop-login">
          <LoginForm
            sendRequest={onSubmit}
            loading={loading}
            error={error}
            dataBlock={dataBlock}
            limpiarVariablesAccountBlock={limpiarVariablesAccountBlock}
          />
        </div>
      </div>
    </section>
  );
};

export default Section1;
